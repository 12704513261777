












































import { Component, Vue } from 'vue-property-decorator';
import KuspitAccountLinkingErrorViewModel
  from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-error-view-model';

@Component({ name: 'KuspitAccountLinkingError' })
export default class KuspitAccountLinkingError extends Vue {
  kuspit_error_view_model = Vue.observable(
    new KuspitAccountLinkingErrorViewModel(this),
  );
}
